import React, { ReactNode } from 'react';
import { ErrorProvider } from 'src/contexts/errorContext';
import { AuthProvider } from 'src/contexts/authContext';

interface ProvidersProps {
  children: ReactNode;
}

export function Providers({ children }: ProvidersProps) {
  return (
    <ErrorProvider>
      <AuthProvider>{children}</AuthProvider>
    </ErrorProvider>
  );
}
