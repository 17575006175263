import axios from 'axios';
import AuthTokenStorageService from '@services/AuthTokenStorageService';
import { useEffect } from 'react';
import { useErrorContext } from '../contexts/errorContext';

const getToken = () => {
  return AuthTokenStorageService.getAccessToken();
};

const apiClient = axios.create({
  baseURL: process.env.BASE_API_URL,
  headers: {
    'Content-type': 'application/json',
    Authorization: '',
    'X-API-KEY': process.env.X_API_KEY,
  },
});

// Set up request interceptor
apiClient.interceptors.request.use(config => {
  const token = getToken();
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

// Set up response interceptor
apiClient.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // Handle API request errors
    // const { handleError } = useErrorContext();
    // handleError('An error occurred during API request.');
    // return Promise.reject(error);
  }
);

const useAxios = () => {
  return apiClient;
};

export default useAxios;
